import { faBusinessTime } from "@fortawesome/pro-light-svg-icons/faBusinessTime";
import Storage from "@datenbanker/storage";
const storage = new Storage();

const privatePages = [
  {
    name: "Aufgaben offen",
    icon: faBusinessTime,
    layout: "appBarOnly",
    component: async () => {
      return import("../resources/views/Task");
    },
    group: "private",
    path: "/tasks/close",
    aliasPath: ["/"]
  }
];

const publicPages = [
  {
    name: "Login",
    layout: "emptyPage",
    component: async () => {
      return import("../resources/views/Login");
    },
    inMenu: false,
    group: "public",
    path: "/login",
    aliasPath: privatePages.reduce((pages, page) => {
      pages.push(page.path);
      return [...pages, ...page.aliasPath];
    }, [])
  }
];

const store = {
  pages: publicPages
};

export default (state = store, action) => {
  let newState = { ...state };
  switch (action.type) {
    case "SIGN_IN":
      newState.pages = privatePages;
      return newState;
    case "SIGN_OUT":
      storage.delete("user:accessToken");
      storage.delete("user:idToken");
      storage.delete("user:refreshToken");
      newState.pages = publicPages;
      return newState;
    default:
      return newState;
  }
};
