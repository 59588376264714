import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Store from "./Store";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { DevCloud } from "@datenbanker/devcloud-client-lib";
import { Theme } from "@datenbanker/react-devcloud-components";
import Storage from "@datenbanker/storage";
const storage = new Storage();
DevCloud.init({
  apiToken:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiIzMmQ0N2I1MC03MGIzLTQzZjMtYTU4Ny0yMTdlMzE3NGNlYWUiLCJzY29wZXMiOlsiYWNiZjI0Y2MtOWFjYy00MmViLWFhYzEtZjI4MjRjNTgwMjgxIl19.RQbO6NTT1axM1aK5Tzle_R5NVs_UjidWkaY1-r8Qo0w",
  services: {
    authentication: {
      user: {
        pool: ["309b7e2e-c07d-4804-820a-84343c364128"]
      }
    }
  },
  handler: {
    tokenChange: tokens => {
      storage.set("user:accessToken", tokens.accessToken);
      storage.set("user:idToken", tokens.idToken);
      storage.set("user:refreshToken", tokens.refreshToken);
    }
  }
});

Theme.init();

ReactDOM.render(
  <Provider store={Store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
