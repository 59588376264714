import React, { Component } from "react";
import { connect } from "react-redux";
import { Router } from "@datenbanker/react-devcloud-components";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.changeGroups = this.changeGroups.bind(this);
  }

  changeGroups(groups) {
    this.setState({ ...this.state, router: { ...this.state.router, groups } });
  }

  render() {
    const { pages } = this.props;
    return (
      <Router
        pages={pages}
        layouter={{
          on: {
            signOut: "SIGN_OUT"
          }
        }}
      />
    );
  }
}

const data = store => {
  return { pages: store.navigation.pages };
};

const actions = dispatch => {
  return {};
};

export default connect(
  data,
  actions
)(App);
